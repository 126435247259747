import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const OfficePage: React.FC<Props> = ({ data, location }: Props) => {
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const office_section2_background = data.office_section2_background?.childImageSharp?.fluid
  const product1 = data.product1?.childImageSharp?.fluid
  const section3_img = data.section3_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Office  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="You're going to love coming to our McCordsville dental office! We're here to treat you like family in our brand new state of the art facility. Dr. Vogt has" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Office - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="You're going to love coming to our McCordsville dental office! We're here to treat you like family in our brand new state of the art facility. Dr. Vogt has" />
      <meta property="og:url" content="https://thedentistsatgc.com/about/office/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2020-12-02T02:53:42+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Office - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="You're going to love coming to our McCordsville dental office! We're here to treat you like family in our brand new state of the art facility. Dr. Vogt has" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="Our Office - The Dentists at Gateway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section" className="text-center">
          <Container>
            <div className="col-lg-12 text-center">
              <p className="hero-text">
                ABOUT OUR MCCORDSVILLE DENTAL OFFICE
              </p>
              <h1 className="text-light">
                A Brand New McCordsville Dental Office
              </h1>
            </div>
            <div className="col-lg-12">
              <p className="hero-text text-light m-t-20">
                You’re going to love coming to our McCordsville dental office! We’re here to treat you like family in our brand new state of the art facility. Dr. Vogt has put in an unbelievable amount of time and effort into this location to be able to provide an outstanding experience for his patients.
              </p>
            </div>
            <div className="col-lg-12 text-center">
              <a href="#office-section-1"><button className="btn-rounded"> Learn More </button></a>
              <a href="https://flexbook.me/tdagc/website" target="_blank"><button className="btn-rounded"> Schedule Now </button></a>
            </div>
          </Container>
          </div>
      </BackgroundImage>
      <section id ="office-section-1" className="page-section text-left">
        <Container>
          <div className="col-lg-12">
            <p className ="section-1-text"> The Dentists At Gateway Crossing - Serving McCordsville, Geist, Fishers, & Indianapolis </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h2 className ="section-1-header">The Dentist At Gateway Crossing Office</h2>
            <p>We designed and built our facility from the ground up to provide an exceptional dental experience a comfortable, clean environment. Whether you utilize our noise-cancelling headphones or pick out your favorite movie to watch on our big-screen TVs, our goal is to provide great care without the sights and smells of the typical dental office.</p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h3 className ="section-1-header">The Dental Equipment</h3>
            <p>We utilize state-of-the-art technology such as digital x-rays, 3D imagery, and high-definition intraoral photography to help us to provide the best care possible. We always stay current to modern trends and techniques in the dental field to make sure your smile is the best it can be. </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h3 className ="section-1-header">The McCordsville Location</h3>
            <p>Conveniently located in McCordsville, one of the fastest growing areas of Indiana, our practice’s vision is to grow with our local community and the patients and families we serve for years to come. </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h3 className ="section-1-header">The Excellent Culture</h3>
            <p>Our mission: To provide exceptional care and service with an attitude of compassion, positivity, and radical generosity. When you arrive in our office, you know you’ll be greeted by name with a smile. We are a judgment-free, relationship-based practice, and we treat our patients like family. </p>
          </div>
        </Container>
        <Container>
          <div className="col-lg-12">
            <h3 className ="section-1-header">The Impact On The Community</h3>
            <p>We are committed to excellence in oral health to our patients for the long-term. We strive to be a dental authority in the Indianapolis area and to continue to grow as a service-driven organization. </p>
          </div>
        </Container>
      </section>
    </Layout>

  )
}

export default OfficePage

export const query = graphql`
  query OfficePageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_img: file(name: { eq: "section3-img" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    office_section2_background: file(name: { eq: "placeholder" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
